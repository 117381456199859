<template>
    <div>
        <b-card no-body class="card-app-design mb-0 bb-lrr-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Team" label-for="team">
                            <v-select v-if="teamListDetail"
                                      :options="teamListDetail" :reduce="(option) => option.value"
                                      v-model="teamFilterUserDetail"
                                      placeholder="Select Team" class="w-100"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                        <b-form-group label="Employee" label-for="employee" v-if="teamUserList">
                            <v-select v-model="selectedUserId" :reduce="(option) => option.key" label="label"
                                      :options="sortedUnityUsers" :clearable="false" placeholder="Select User">
                                <template #option="{ image, label }">
                                    <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                    <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                </template>
                                <template #selected-option="{ image, label }">
                                    <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                    <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                </template>
                            </v-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Date Interval" label-for="dateInterval">
                            <flat-pickr v-model="searchIntervalDateDetail"
                                        :config="{mode: 'range',defaultDate:[searchReportDiffMonthDateDetail,searchReportDateDetail]}"
                                        class="form-control" placeholder="YYYY-MM-DD | YYYY-MM-DD" :clearable="true"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Date Types" label-for="role">
                            <v-select class="text-capitalize" v-model="dateTypeDetail"
                                      label="type" :options="['Hourly', 'Hourly 24']" :clearable="false"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Event Types" label-for="role">
                            <v-select
                                v-model="eventFilterDetail"
                                :reduce="(option) => option.value"
                                label="label"
                                :options="eventTypes"
                                :clearable="false"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="1 my-auto" v-if="selectedUser">
                        <div class="design-planning-wrapper">
                            <div class="design-planning mb-0">
                                <p class="card-text mb-25">
                                    Apps
                                </p>
                                <h6 class="mb-0">
                                    {{ selectedUser.total_app }}
                                </h6>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card no-body id="guruTableScroll" class="mb-0 mt-0" v-if="selectedUser && selectedUserId && !isLoading">
            <div class="guru" v-if="selectedUser">
                <div class="guru_row guru_row-hours sticky"
                     :style="'grid-template-columns: 150px repeat('+selectedUser.grid+', 1fr);'">
                    <div class="guru_row-first  d-flex  align-items-center">Date</div>
                    <span v-for="(date, index) in selectedUser.periods"
                          :key="index">{{ formatHourlyDate(date) }}</span>
                </div>
                <div class="guru_row guru_row-lines"
                     :style="'grid-template-columns: 150px repeat('+selectedUser.grid+', 1fr);'">
                    <span></span>
                    <span v-for="(date, index) in selectedUser.periods" :key="index"></span>
                </div>
                <div class="guru_row" v-for="(userlist, index) in selectedUser.dates" :key="index"
                     ref="refDiscordUsers">
                    <div class="guru_row-first">
                        <b-media vertical-align="center">
                            <b-link class="font-weight-bold d-block text-nowrap">
                                {{ userlist.date }}
                            </b-link>
                            <small class="text-muted">Score: {{ userlist.total_event }}</small>
                        </b-media>
                    </div>

                    <ul class="guru_row-bars p-0"
                        :style="'grid-template-columns: repeat('+selectedUser.grid+', 1fr)!important;'">
                        <li v-for="(event,index) in userlist.events.filter(item => item.count > 0)" :key="index"
                            class="m-20 justify-content-center"
                            @click="event.count>0?getDetailInfo(userlist,event,dateTypeDetail):null"
                            :style="hourlyColumnFormat(event.key,event.count,userlist.total_event)"
                            v-b-tooltip.html.hover.top="
                               `${eventFilterDetail === null ? `Total Score: ${event.count} <br/>` : ''}
                                ${event.script_reload !== 0 ? `Script Reload: ${event.script_reload} <br/>` : '' }
                                ${event.project_changed !== 0 ? `Project Changed: ${event.project_changed} <br/>`: ''}
                                ${event.hierarchy_changed !== 0 ? `Hierarchy Changed: ${event.hierarchy_changed} <br/>`: ''}
                                ${event.asset_saved !== 0 ? `Asset Saved: ${event.asset_saved} <br/>`: ''}
                               `">
                            {{ event.count }}
                        </li>
                    </ul>
                </div>
            </div>
            <unity-user-detail ref="modal" :modalDate="modalDate" :dateTypes="dateTypes"></unity-user-detail>
        </b-card>
        <b-card v-if="isLoading" class="mt-2">
            <div class="text-center my-2">
                <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </b-card>
        <b-card v-if="!isLoading &&  (!selectedUserId || !selectedUser)" class="mt-2">
            <div class="d-flex align-items-center">
                <strong>Not Found.</strong>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BLink,
    BMedia,
    BOverlay,
    BPagination,
    BRow,
    BSpinner,
    BTable,
    VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {avatarText} from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import useUnityApi from '@/composables/useUnityApi'
import moment from 'moment'
import UnityUserDetail from '@/components/UnityUserDetail.vue'

export default {
    components: {
        vSelect,
        BFormGroup,
        BSpinner,
        BOverlay,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        flatPickr,
        UnityUserDetail
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            modalDate: null,
            dateTypes: null
        }
    },
    setup() {
        const {
            fetchUnityUsers,
            fetchUnityUser,
            fetchUnityUserInfo,
            fetchUnityUserList,
            fetchTeamList,

            isLoading,
            searchReportDateDetail,
            searchIntervalDateDetail,
            searchReportDiffMonthDateDetail,
            eventFilterDetail,
            teamFilterUserDetail,
            teamListDetail,
            eventTypes,
            firstLoad,
            dateTypeDetail,
            teamUserList,
            selectedUserId,
            selectedUser
        } = useUnityApi()
        return {
            avatarText,
            fetchUnityUsers,
            fetchUnityUser,
            fetchUnityUserInfo,
            fetchUnityUserList,
            fetchTeamList,

            isLoading,
            searchReportDateDetail,
            searchIntervalDateDetail,
            searchReportDiffMonthDateDetail,
            eventFilterDetail,
            teamFilterUserDetail,
            teamListDetail,
            eventTypes,
            firstLoad,
            dateTypeDetail,
            teamUserList,
            selectedUserId,
            selectedUser
        }
    },
    mounted() {
        this.fetchTeamList()
    },
    created() {
        if (this.$route.params.userid) {
            this.selectedUserId = parseInt(this.$route.params.userid)
        }
        if (!this.$route.params.userid && this.teamFilterUserDetail === null) this.teamFilterUserDetail = 1
    },
    updated() {
        if (!this.$route.params.userid && this.selectedUser === null) {
            if (this.teamUserList) this.selectedUser = this.teamUserList ? this.teamUserList[0] : null
            this.selectedUserId = this.teamUserList ? this.teamUserList[0].key : null
        }
    },
    methods: {
        formatHourlyDate(date) {
            return moment(date).format('HH:mm')
        },
        hourlyColumnFormat(key, count) {
            if (count === 0) return 'grid-column:' + key + ';background:none'
            let normalize = (count - 0) / (100)
            let colorText = normalize < 0.5 ? "#232323" : '#f7f7f7'
            let background = `rgb(9, 191, 44, ${normalize})`;
            return `grid-column:${key};color:${colorText};background:${background}`
        },
        getDetailInfo(user, date, dateTypes) {
            this.modalDate = user.date + ' ' + date.date
            this.dateTypes = dateTypes
            this.fetchUnityUserInfo(this.selectedUserId, user.date + ' ' + date.date, true)
            this.$root.$emit("bv::show::modal", "modal-primary")
        },
    },
    computed: {
        sortedUnityUsers: function () {
            return this.teamUserList.sort((a, b) => a.label.localeCompare(b.label))
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.tooltip-inner {
    text-align: left;
}
</style>
