// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line object-curly-newline
import {ref, computed, watch, onMounted} from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'

export default function useUnityApi() {
    // Use toast
    const toast = useToast()
    const unityUsers = ref(null)

    const isLoading = ref(true)
    const refunityUsers = ref(null)
    const searchReportDate = ref(moment().format('YYYY-MM-DD'))
    const searchReportDateDetail = ref(moment().format('YYYY-MM-DD'))
    const searchReportDiffMonthDate = ref(moment().subtract(2, 'weeks').format('YYYY-MM-DD'))
    const searchReportDiffMonthDateDetail = ref(moment().subtract(2, 'weeks').format('YYYY-MM-DD'))
    const searchIntervalDate = ref(null)
    const searchIntervalDateDetail = ref(null)
    const searchIntervalDateRepo = ref(null)
    const searchDate = ref(moment().format('YYYY-MM-DD'))
    const unityRepo = ref(null)
    const teamFilter = ref(null)
    const teamFilterUser = ref(null)
    const teamFilterDetail = ref(null)
    const teamFilterUserDetail = ref(null)

    const teamList = ref(null)
    const teamListDetail = ref(null)
    const teamUserList = ref(null)
    const selectedUser = ref(null)
    const selectedUserId = ref(null)
    const roleFilter = ref('Developer')
    const eventFilter = ref(null)
    const eventFilterDetail = ref(null)
    const dateType = ref('Hourly')
    const dateTypeDetail = ref('Hourly')
    const firstLoad = ref(false)
    const roleOptions = [
        {label: 'All', value: null},
        {label: 'Developer', value: 'Developer'},
        {label: '3D Artist', value: '3D Artist'},
    ]
    const eventTypes = [
        {label: 'All', value: null},
        {label: 'Script Reload', value: 'script_reload'},
        {label: 'Project Changed', value: 'project_changed'},
        {label: 'Hierarchy Changed', value: 'hierarchy_changed'},
        {label: 'Asset Saved', value: 'asset_saved'},
    ]

    // ------------------------------------------------
    // Get Unity Users
    // ------------------------------------------------
    watch([searchIntervalDate, roleFilter, eventFilter, dateType, searchDate, teamFilterUser], () => {
        isLoading.value = false
        fetchUnityUsers()
    })

    const fetchUnityUsers = (ctx, callback) => {
        const customdate = searchIntervalDate.value ? searchIntervalDate.value.split('to') : [searchReportDiffMonthDate.value, searchReportDate.value]
        if (customdate.length !== 2) return
        if (dateControl(customdate[1], customdate[0])) return
        isLoading.value = true
        store
            .dispatch('unityStore/fetchUnityUsers', {
                team: teamFilterUser.value,
                role: roleFilter.value,
                type: eventFilter.value !== null ? {
                    script_reload: eventFilter.value === 'script_reload',
                    project_changed: eventFilter.value === 'project_changed',
                    hierarchy_changed: eventFilter.value === 'hierarchy_changed',
                    asset_saved: eventFilter.value === 'asset_saved'
                } : null,
                start_date: dateType.value === 'Daily' || dateType.value === 'Daily 24' ? customdate[0] : searchDate.value,
                end_date: dateType.value === 'Daily' || dateType.value === 'Daily 24' ? customdate[1] : searchDate.value,
                date_type: dateType.value
            })
            .then(response => {
                unityUsers.value = response.data
                isLoading.value = false
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    const fetchUnityUserInfo = (id, date, is_detail) => {
        const funDate = is_detail ? date : dateType.value === 'Hourly' || dateType.value === 'Hourly 24' ? searchDate.value + ' ' + date : date;
        store.dispatch('unityStore/fetchUserInfoView', {
            id: id,
            date: funDate,
            dateType: dateType.value === 'Hourly' || dateType.value === 'Hourly 24' ? 'Hourly' : dateType.value
        }).then(response => response.data).catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }

    const dateControl = (start, end) => {
        let maxDiffDay = moment(start).diff(moment(end), 'days')
        if (maxDiffDay > 31) {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Info',
                    icon: 'InfoIcon',
                    variant: 'info',
                    text: 'The date range must be a maximum of 1 month.',
                },
            })
            return true
        }
        return false
    }

    watch([teamFilterUserDetail], () => {
        const control = selectedUser.value ? selectedUser.value.team_id : null
        control !== teamFilterUserDetail.value ? selectedUserId.value = null : selectedUserId.value
        isLoading.value = false
        fetchUnityUserList()
    })

    watch([searchIntervalDateDetail, eventFilterDetail, dateTypeDetail, selectedUserId], () => {
        if (selectedUserId.value !== null) {
            isLoading.value = false
            fetchUnityUser()
        }
    })


    const fetchUnityUser = () => {
        isLoading.value = true
        const customdate = searchIntervalDateDetail.value ? searchIntervalDateDetail.value.split('to') : [searchReportDiffMonthDateDetail.value, searchReportDateDetail.value]
        store.dispatch('unityStore/fetchUserDetailView', {
            id: selectedUserId.value,
            type: eventFilterDetail.value !== null ? {
                script_reload: eventFilterDetail.value === 'script_reload',
                project_changed: eventFilterDetail.value === 'project_changed',
                hierarchy_changed: eventFilterDetail.value === 'hierarchy_changed',
                asset_saved: eventFilterDetail.value === 'asset_saved'
            } : null,
            dateType: dateTypeDetail.value,
            start_date: customdate[0],
            end_date: customdate[1],
        }).then(response => {
            const {data} = response.data
            isLoading.value = false
            teamFilterUserDetail.value = data.team_id
            selectedUser.value = data
        }).catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }

    const fetchTeamList = () => {
        store.dispatch('teamStore/fetchTeams').then(response => {
            const {data} = response.data
            isLoading.value = false
            teamList.value = data.map(item => ({value: item.id, label: item.name}))
            teamListDetail.value = data.map(item => ({value: item.id, label: item.name}))
        }).catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }

    const fetchUnityUserList = () => {
        store.dispatch('unityStore/fetchUnityAllUsers', {
            team: teamFilterUserDetail.value
        }).then(response => {
            const {data} = response.data
            isLoading.value = false
            teamUserList.value = data.map(item => ({key: item.id, label: item.name, image: item.image}))
        }).catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }


    return {
        unityUsers,
        fetchUnityUsers,
        fetchUnityUser,
        fetchUnityUserInfo,
        fetchUnityUserList,
        fetchTeamList,
        isLoading,
        refunityUsers,
        searchReportDate,
        searchReportDateDetail,
        searchIntervalDate,
        searchIntervalDateDetail,
        searchReportDiffMonthDate,
        searchReportDiffMonthDateDetail,
        unityRepo,

        searchIntervalDateRepo,
        roleOptions,
        roleFilter,


        teamUserList,
        selectedUserId,
        selectedUser,

        eventTypes,
        eventFilter,
        eventFilterDetail,

        dateType,
        dateTypeDetail,
        searchDate,

        teamFilterUser,
        teamFilter,
        teamFilterUserDetail,
        teamFilterDetail,
        teamList,
        teamListDetail,

        firstLoad,
    }
}
