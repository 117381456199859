<template>
    <b-modal size="lg" id="modal-primary" hide-footer modal-class="modal-primary" centered @hidden="resetDetail">
        <b-card-text v-if="!isLoading">
            <div class="row" v-if="unityDetail.detail">
                <div class="col-12 card-app-design">

                    <div class="design-planning-wrapper d-flex justify-content-between">
                        <b-media no-body class="mb-50">
                            <b-media-aside class="mr-75 my-auto">
                                <b-avatar size="36" :src="unityDetail.detail.image"
                                          :text="avatarText(unityDetail.detail.name)"/>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h6 class="mb-0" style="max-width:120px">
                                    {{ unityDetail.detail.name }}
                                </h6>
                                <small style="max-width:120px; float:left">{{
                                        dateTypes === 'Daily' ? modalDate : `${modalDate}:00 ${modalDate}:59`
                                    }}</small>
                            </b-media-body>
                        </b-media>
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="design-planning mb-50">
                                <p class="card-text mb-25">
                                    Total Score
                                </p>
                                <h6 class="mb-0">
                                    {{ unityDetail.detail.total_event }}
                                </h6>
                            </div>

                            <div class="design-planning mb-50">
                                <p class="card-text mb-25">
                                    Script Rel.
                                </p>
                                <h6 class="mb-0">
                                    {{ unityDetail.detail.script_reload }}
                                </h6>
                            </div>
                            <div class="design-planning mb-50">
                                <p class="card-text mb-25">
                                    Project Chang.
                                </p>
                                <h6 class="mb-0">
                                    {{ unityDetail.detail.project_changed }}
                                </h6>
                            </div>
                            <div class="design-planning mb-50">
                                <p class="card-text mb-25">
                                    Hierarc. Chang.
                                </p>
                                <h6 class="mb-0">
                                    {{ unityDetail.detail.hierarchy_changed }}
                                </h6>
                            </div>
                            <div class="design-planning mb-50">
                                <p class="card-text mb-25">
                                    Asset Saved
                                </p>
                                <h6 class="mb-0">
                                    {{ unityDetail.detail.asset_saved }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 card-app-design" v-for="(app, index) in unityDetail.apps" :key="index">
                    <hr>

                    <div class="design-planning-wrapper d-flex justify-content-between">
                        <b-media v-if="app.appNames" class="mb-50" no-body v-b-tooltip.html.hover.top="`
                            ${app.appNames ? app.appNames.join('<br/>') : ''}
                            `">
                            <b-media-aside class="mr-75 my-auto">
                                <b-avatar rounded="sm" size="36" :src="app.icon ? backend_url+app.icon: null"
                                          :text="avatarText(app.name)"/>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h6 class="mb-0" style="max-width:120px">
                                    {{ app.name }}
                                </h6>
                            </b-media-body>
                        </b-media>
                        <b-media no-body v-if="!app.appNames">
                            <b-media-aside class="mr-75 my-auto">
                                <b-avatar rounded="sm" size="36" :src="app.icon ? backend_url+app.icon: null"
                                          :text="avatarText(app.name)"/>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h6 class="mb-0" style="max-width:120px">
                                    {{ app.name }}
                                </h6>
                            </b-media-body>
                        </b-media>
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="design-planning mb-50">
                                <p class="card-text mb-25">
                                    Total Score
                                </p>
                                <h6 class="mb-0">
                                    {{ app.total_event }}
                                </h6>
                            </div>
                            <div class="design-planning mb-50">
                                <p class="card-text mb-25">
                                    Script Rel.
                                </p>
                                <h6 class="mb-0">
                                    {{ app.script_reload }}
                                </h6>
                            </div>
                            <div class="design-planning mb-50">
                                <p class="card-text mb-25">
                                    Project Chang.
                                </p>
                                <h6 class="mb-0">
                                    {{ app.project_changed }}
                                </h6>
                            </div>
                            <div class="design-planning mb-50">
                                <p class="card-text mb-25">
                                    Hierarc. Chang.
                                </p>
                                <h6 class="mb-0">
                                    {{ app.hierarchy_changed }}
                                </h6>
                            </div>
                            <div class="design-planning mb-50">
                                <p class="card-text mb-25">
                                    Asset Saved
                                </p>
                                <h6 class="mb-0">
                                    {{ app.asset_saved }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-card-text>
        <div v-else-if="isLoading" class="text-center my-2">
            <b-spinner class="d-block mx-auto mb-50"></b-spinner>
            <strong>Loading...</strong>
        </div>
    </b-modal>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    VBModal,
    BModal,
    BButton,
    BCardText,
    BBadge,
    BAvatarGroup,
    BAvatar,
    VBTooltip,
    BMediaAside,
    BMedia,
    BMediaBody,
    BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {avatarText} from '@core/utils/filter'
import {mapState} from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCollapse,
        BFormCheckbox,
        BModal,
        BButton,
        BCardText,
        BBadge,
        BAvatarGroup,
        BAvatar,
        BMediaAside,
        BMedia,
        BMediaBody,
        BSpinner,
        AppTimeline,
        AppTimelineItem,
    },
    props: {
        modalDate: {
            type: String,
            required: false,
        },
        dateTypes: {
            type: String,
            required: false,
        },
        events: {}
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        Ripple,
    },
    setup() {
        return {
            avatarText,
        }
    },
    data() {
        return {
            backend_url: process.env.VUE_APP_BACKEND_URL
        }
    },
    computed: {
        ...mapState('unityStore', ['unityDetail', 'isLoading'])
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('HH:mm');
        },
        resetDetail() {
            this.$store.dispatch('unityStore/resetDetailView');
        }
    }
}
</script>
<style>
</style>
